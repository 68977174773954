import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { FaHeartCircleMinus, FaHeartCirclePlus} from 'react-icons/fa6';
import { TbCategoryMinus, TbCategoryPlus } from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import { SetterOrUpdater, useRecoilCallback, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { AREA_DAILY_HOURLY_CURRENT_FORECAST } from '../../graphql/areas/queries';
import { singleAreaState, removeAreaIdNameWithToast, favoriteAreasIdNameState, areaIdNameState, addAreaIdNameWithToast, compareAreasIdNameState, currentLocationState, loadCurrentLocation, showWeeklyForecastState } from '../../state/areasState';
import Loading from '../common/Loading';
import Title from '../common/Title';
import CardCarousel from '../cards/CardCarousel';
import { navState } from '../../state/navState';
import MissingPageComponent from '../common/MissingPage';
import List from '../common/List';
import { ListItem } from '../../types/ListItem';
import { Toaster, toast } from 'react-hot-toast';
import ClimbitChart from '../charts/ClimbitChart';
import AreaHeader1 from '../../images/KyleHeader.png';
import AreaHeader2 from '../../images/ShockHeader.png';
import HourlyForecasts from '../Forecast/HourlyForecasts';
import DateButtons from '../buttons/DateButtons';
import { HourlyForecast, DailyForecast, PastPrecip } from '../../types/Forecast';
import { maxAreas } from '../compare/Compare';
import { AreaIdName } from '../../types/Area';
import Tooltip from '../common/Tooltip';
import EnableLocationServicesComponent from '../user/EnableLocationServices';
import Dropdown from '../common/Dropdown';
import PastPrecipComponent from '../Forecast/PastPrecip';
import MountainProjectButton from '../buttons/MountainProjectButton';
import GoogleMapsButton from '../buttons/GoogleMapsButton';
import { compareAreasState } from '../../state/compareAreasState';
import { daytimeOnlyState } from '../../state/daytimeOnlyState';
import { weatherModelState } from '../../state/weatherModelState';
import { pastPrecipHoursState } from '../../state/pastPrecipHoursState';
import HourlyForecastCardComponent from '../cards/HourlyForecastCard';
import HourlyForecastTable from '../Forecast/HourlyForecastTable';
import { unitPreferencesState } from '../../state/unitPreferencesState';
import TooManyRequestsComponent from '../common/TooManyRequests';
import UnknownErrorComponent from '../common/UnknownError';
import SunShadeComponent from './SunShadeComponent';
import { sunShadeState } from '../../state/sunShadeState';
import { Switch } from '@headlessui/react';
import { createUrlSubdomain, lineColors } from '../../utils/CommonUtils';
import ShareButton from '../buttons/ShareButton';

const Area = () => {
    const params: any = useParams();
    const navigate = useNavigate();
    const isCurrentLocation = params.id === 'cl';
    const [currentLocationPayload, setCurrentLocationPayload] = useState<any>(undefined);
    const [userLocationError, setUserLocationError] = useState<string | null>(null);
    const currentLocation = useRecoilValue(currentLocationState);
    const daytimeOnly = useRecoilValue(daytimeOnlyState);
    const weatherModel = useRecoilValue(weatherModelState);
    const pastPrecipHours = useRecoilValue(pastPrecipHoursState);
    const {temperaturePreferences, metricUnits} = useRecoilValue(unitPreferencesState);
    const [isMounted, setIsMounted] = useState(false);
    const [areaIdName, setAreaIdName] = useRecoilState(areaIdNameState);
    const [chosenAreaHeader, setChosenAreaHeader] = useState('');
    const [area, setArea] = useRecoilState<any>(singleAreaState(params.id));
    const [sunShade, setSunShade] = useRecoilState(sunShadeState);

    useEffect(() => {
        // Generate a random number between 0 and 1
        const randomIndex = Math.random() < 0.5 ? 0 : 1;
  
        // Choose the AreaHeader based on the random number
        const areaHeader = randomIndex === 0 ? AreaHeader1 : AreaHeader2;
  
        // Set the chosenAreaHeader state to the selected AreaHeader
        setChosenAreaHeader(areaHeader);

        // This code will run when the component mounts
        setIsMounted(true);
  
        // Cleanup function (optional)
        return () => {
            // This code will run when the component unmounts
            setIsMounted(false);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        if (areaIdName) {
            const areaNameTitle = createUrlSubdomain(areaIdName.name);
            if (!params.title || params.title === 'redirect') {
                navigate(`/area/${params.id}/${areaNameTitle}`, { replace: true });
            }   
            document.title = `${areaIdName.name} | Rock Climbing Weather Forecasts`;
        }
    }, [areaIdName, params.id, params.title]);

    const updateCurrentLocation = useRecoilCallback(
        ({ set }) => async () => {
            try {
                await loadCurrentLocation(set);
            }   catch (err: any) {
                // Handle the error and set the error state
                setUserLocationError(err.message);
            }
            
        },
        []
    );
  
    useEffect(() => {
        // Define an asynchronous function to use await
        const fetchData = async () => {
            // Load current location only if it's not already set
            if (isCurrentLocation && !currentLocation) {
                await updateCurrentLocation();
            }
        };

        // Immediately invoke the asynchronous function
        fetchData();
    }, [currentLocation, updateCurrentLocation]);

    useEffect(() => {
        if (isCurrentLocation && currentLocation) {
            setCurrentLocationPayload({
                id: 'cl',
                name: 'Current Location',
                longitude: currentLocation.longitude,
                latitude: currentLocation.latitude,
                timezone: currentLocation.timezone,
                googleMaps: currentLocation.googleMaps
            });
            refetchQuery();
        }
        
    }, [currentLocation]);

    useEffect(() => {
        if (data && !loading) {
            refetchQuery();
        }
      
    }, [daytimeOnly, weatherModel, pastPrecipHours, temperaturePreferences, metricUnits, sunShade]); // Run this effect whenever preferences change

    const { data, loading, error, refetch: refetchQuery } = useQuery(AREA_DAILY_HOURLY_CURRENT_FORECAST, {
        variables: {
            id: params.id,
            currentLocationPayload: isCurrentLocation ? currentLocationPayload : undefined,
            daytimeOnly,
            weatherModel,
            pastPrecipHours,
            temperaturePreferences,
            metricUnits,
            sunShade: sunShade[params.id] || 0
        },
        skip: isCurrentLocation && !currentLocationPayload,
    });

    const resetNavState = useResetRecoilState(navState);
    const [cards, setCards] = useRecoilState(compareAreasState);
    const dailyForecastRef = useRef<HTMLDivElement>(null);

    const [currentDayIndex, setCurrentDayIndex] = useState<number>(0);
    const [currentDay, setCurrentDay] = useState<DailyForecast | null>(null);
    const [dailyForecast, setDailyForecast] = useState<DailyForecast[]>([]);
    const [hourlyForecast, setHourlyForecast] = useState<HourlyForecast[]>([]);
    const [currentHourlyForecast, setCurrentHourlyForecast] = useState<HourlyForecast | null>(null);
    const [weeklyHourlyForecast, setWeeklyHourlyForecast] = useState<HourlyForecast[][]>([]);

    const [pastPrecip, setPastPrecip] = useState<PastPrecip | undefined>(undefined);
    const bestTimesToClimb =
    area?.forecasts?.bestTimesToClimb?.hourlyForecast;
    const [favoriteAreasIdName, setFavoriteAreasIdNames] = useRecoilState(favoriteAreasIdNameState);
    const [compareAreasIdName, setCompareAreasIdNames] = useRecoilState(compareAreasIdNameState);
    const [showWeeklyForecast, setShowDailyForecast] = useRecoilState(showWeeklyForecastState);

    const updateShadeSun = (value: number, id: string) => {
        setSunShade(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    useEffect(() => {
        resetNavState();
        const isValid = !!data?.area?.forecasts;
        if (isValid) {
            setArea(data.area);
            if (sunShade[data.area.id] === 0 || !sunShade[data.area.id] && data.area.sunShade) {
                updateShadeSun(data.area.sunShade, data.area.id);
            } 

            setAreaIdName({
                id: data.area.id,
                path: data.area.path,
                name: data.area.name
            });
            if (data.area.forecasts.pastPrecip) setPastPrecip(data.area.forecasts.pastPrecip);
            if (data.area.forecasts.dailyForecast && data.area.forecasts.dailyForecast[0]) setCurrentDay(data.area.forecasts.dailyForecast[0]);
            if (data.area.forecasts.dailyForecast) setDailyForecast(data.area.forecasts.dailyForecast);
            if (data.area.forecasts.currentWeather) setCurrentHourlyForecast(data.area.forecasts.currentWeather);
        }
    }, [data]);

    useEffect(() => {
        if (!area) return;
        setCurrentDay(dailyForecast[currentDayIndex]);
    }, [currentDayIndex, dailyForecast, area]);

    useEffect(() => {
        if (!currentDay) return;
        setHourlyForecast(
            area?.forecasts?.hourlyForecast?.filter(
                ({ date }: HourlyForecast) => date === currentDay.date
            )
        );
        setWeeklyHourlyForecast([area?.forecasts?.hourlyForecast?.filter((_: any, i: number) => i % 4 === 0 )]);
    }, [currentDay, area]);

    const addCompareAreaIdNameWithToast = (
        selectedAreaIdName: AreaIdName,
        areasIdName: AreaIdName[],
        setAreasIdName: SetterOrUpdater<AreaIdName[]>,
        toast:any
    ) => {
        if(compareAreasIdName.length >= maxAreas) {
            toast.error('Too many areas exist! Please remove an area from the comparison page and try again.', {
                duration: 3500,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            });
        } else {
            addAreaIdNameWithToast(selectedAreaIdName, areasIdName ,setAreasIdName , 'comparison', toast);
            navigate('/compare');
        }
        
    };

    const listItems: ListItem[] | undefined = area?.childrenList
        ? area?.childrenList.map((child: any) => {
            return {
                title: child.name,
                link: `/area/${child.id}/${createUrlSubdomain(child.name)}`,
            };
        })
        : undefined;
    if (userLocationError && isMounted) return <EnableLocationServicesComponent message={userLocationError}/>;
    if (loading || (isCurrentLocation && !currentLocation && !userLocationError) && isMounted) return <Loading />;
    if (error && error?.message.includes('429')) return <TooManyRequestsComponent/>;
    if (error && !error?.message.includes('429')) return <UnknownErrorComponent/>;
    if (!area && !loading && isMounted) return <MissingPageComponent />;

    return (
        <div className="bg-white-secondary pb-4">
            <div className='flex flex-col justify-center items-center'>
                <div className="flex flex-col md:flex-row w-full md:justify-between md:pr-8">
                    <Title title={area?.name} header={area?.path} />
                    <div className='flex flex-col items-center justify-center md:space-y-6'>
                        <div className="flex lg:justify-start justify-center items-center">
                            {area && areaIdName && (
                                <div className="md:mr-6 p-2 flex gap-x-2">
                                    <Toaster
                                        position="top-center"
                                        reverseOrder={false}
                                        gutter={8}
                                        containerClassName="toast"
                                        containerStyle={{}}
                                        toastOptions={{
                                            className: '',
                                            duration: 3500,
                                            style: {
                                                background: '#363636',
                                                color: '#fff',
                                            }
                                        }}/>     
                                    { !isCurrentLocation && !favoriteAreasIdName.some((area) => area.id === areaIdName.id) && (
                                        <Tooltip text='Favorite Area' className='text-sm text-center' position='centered mt-1'>
                                            <button className='md:px-4 px-3 py-1 border-2 rounded-md button shadow-md' onClick={() => {
                                                addAreaIdNameWithToast(areaIdName, favoriteAreasIdName, setFavoriteAreasIdNames, 'favorites', toast);
                                            }}>
                                                <FaHeartCirclePlus size={25} className='fill-black'/>
                                            </button> 
                                        </Tooltip>
                                    )}
                                    { !isCurrentLocation && favoriteAreasIdName.some((area) => area.id === areaIdName.id) && (
                                        <Tooltip text='Remove Favorite' className='text-sm text-center' position='centered mt-1'>
                                            <button
                                                className="md:px-4 px-3 py-1 border-2 rounded-md button shadow-md relative"
                                                onClick={() => removeAreaIdNameWithToast(areaIdName, favoriteAreasIdName, setFavoriteAreasIdNames, 'favorites', toast)}
                                            >
                                                <FaHeartCircleMinus size={25} className='fill-red' />
                                            </button> 
                                        </Tooltip>
                                    )}   
                                    {pastPrecip !== undefined && (
                                        <PastPrecipComponent pastPrecip={pastPrecip}/>
                                    )}        
                                    { !isCurrentLocation && compareAreasIdName.some((area) => area.id === areaIdName.id) && (
                                        <Tooltip text='Remove Comparison' className='text-sm text-center' position='centered mt-1'>

                                            <button className='md:px-4 px-3 py-1 border-2 rounded-md button shadow-md' onClick={() => {
                                                removeAreaIdNameWithToast(areaIdName, compareAreasIdName, setCompareAreasIdNames, 'comparison', toast);
                                                setCards(cards.filter((card) => card.id !== areaIdName.id));

                                            }}>
                                                <span className='hidden md:block'>
                                                    <div className='flex flex-row gap-1 items-center justify-center '>
                                                        <TbCategoryMinus className='' size={22}  />
                                                        Compare
                                                    </div>
                                                </span>
                                                <TbCategoryMinus className='block md:hidden' size={25}  />
                                            </button>
                                        </Tooltip>
                                    )}     
                                    { !isCurrentLocation && !compareAreasIdName.some((area) => area.id === areaIdName.id) && (
                                        <Tooltip text='Add To Compare' className='text-sm text-center' position='centered mt-1'>
                                            <button className='md:px-4 px-3 py-1 border-2 rounded-md button shadow-md' onClick={() => {
                                                addCompareAreaIdNameWithToast(areaIdName, compareAreasIdName, setCompareAreasIdNames, toast);
                                            }}>
                                                <span className='hidden md:block'>
                                                    <div className='flex flex-row gap-1 items-center justify-center '>
                                                        <TbCategoryPlus className='' size={22}  />
                                                        Compare
                                                    </div>
                                                </span>
                                                <TbCategoryPlus className='block md:hidden' size={25}  />
                                            </button>
                                        </Tooltip>
                                    )}
                                    {area?.mountainProject && (
                                        <MountainProjectButton area={area}/>
                                    )}
                                    {area?.googleMaps && (
                                        <GoogleMapsButton areaCity={area}/>
                                    )}
                                    {!isCurrentLocation && (
                                        <ShareButton title={`${area.name} | Climbit Weather Forecast`} url={window.location.href} />
                                    )
                                    }
                                </div>
                            )}
                            
                        </div>
                        {listItems && listItems.length > 0 && (
                            <div className="pb-2 text-center md:hidden block">
                                <Dropdown title='Sub Areas' items={listItems} />
                            </div>
                        )}
                        {!isCurrentLocation && areaIdName && (
                            <SunShadeComponent areaId={areaIdName.id}/>
                        )}
                    </div>
                </div>
            </div>
            
            {currentDay && (
                <>
                    <div className="flex flex-col md:flex-row justify-center md:mx-4 select-none md:mt-6">
                        <div className="flex flex-col md:flex-row pb-3 hide-scroll-bar justify-center">
                            {listItems && listItems.length > 0 && (
                                <>
                                    <div className="pb-2 px-3 hidden md:block">
                                        <h1 className="text-xl font-bold text-center text-gray-dark mb-2">
                                            Sub Areas
                                        </h1>
                                        <div className="overflow-scroll md:max-h-96 max-h-32 max-w-lg">
                                            {listItems && listItems.length > 0 && (
                                                <List items={listItems} />
                                            )}
                                        </div>
                                    </div>
                                </>         
                            )}
                            
                            <div className="relative flex justify-center items-center w-full">
                                <div className="absolute inset-0 bg-black opacity-30"></div>
                                <img
                                    src={chosenAreaHeader}
                                    className="rounded-lg h-full min-h-[370px] object-cover w-full hidden md:block"
                                    alt="image"
                                />
                                {currentHourlyForecast && (
                                    <>

                                        <div className={`md:absolute md:right-0 md:top-0 md:mr-20 h-full ${currentHourlyForecast.isDay ? 'md:bg-white': 'md:bg-gray-light'} justify-center items-center hidden md:flex`}>
                                            <HourlyForecastCardComponent
                                                key={currentHourlyForecast.displayTime}
                                                forecast={currentHourlyForecast}
                                                currentWeather={true}
                                                className='md:py-2'
                                                overImage={true}
                                            />
                                        </div>
                                        <div className="md:hidden relative w-full bg-white-secondary pt-4 px-2">
                                            <HourlyForecastTable
                                                forecasts={[currentHourlyForecast]}
                                                currentWeather={true}
                                            /> 
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>)}

            {dailyForecast && (
                <div className="flex flex-col p-1 hide-scroll-bar flex-shrink-0 items-center justify-center">
                    <CardCarousel dailyForecasts={dailyForecast} hourlyForecasts={hourlyForecast} currentDayIndex={currentDayIndex} onClick={(id: number) => setCurrentDayIndex(id)} refToScroll={dailyForecastRef} bestTimesToClimb={bestTimesToClimb} allHourlyForecasts={area?.forecasts?.hourlyForecast}/>
                </div>)}
            <div className="flex flex-col m-auto max-w-[1300px]" id="dailyForecast" ref={dailyForecastRef}>
                {hourlyForecast && currentDay && weeklyHourlyForecast && (
                    <>
                        <div className="flex self-center md:self-end flex-row items-center mb-2 px-2 md:px-4">
                            <span className='font-semibold text-lg'>Daily</span>
                            <Switch
                                checked={showWeeklyForecast}
                                onChange={() => setShowDailyForecast(!showWeeklyForecast)}
                                className={`${showWeeklyForecast ? 'bg-primary' : 'bg-gray opacity-70'}
                    relative inline-flex h-[22px] w-[56px] ml-2 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                            >
                                <span
                                    aria-hidden="true"
                                    className={`${showWeeklyForecast ? 'translate-x-8' : 'translate-x-0'}
                        pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                            </Switch>
                            <span className='font-semibold text-lg pl-2'>Weekly</span>
                        </div>

                        <ClimbitChart
                            hourlyForecasts={hourlyForecast}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                            color={lineColors.climbitScoreLineColor}
                        />
                        <DateButtons
                            currentIndex={currentDayIndex}
                            setCurrentIndex={setCurrentDayIndex}
                            length={dailyForecast.length}
                            date={currentDay.displayDate}
                        />
                        <HourlyForecasts
                            forecasts={hourlyForecast}
                            bestTimeToClimb={
                                bestTimesToClimb
                                    ? bestTimesToClimb?.find(
                                        ({ date }: HourlyForecast) => date === currentDay.date
                                    )
                                    : undefined
                            }
                            sunrise={currentDay ? new Date(currentDay.sunrise) : undefined}
                            sunset={currentDay ? new Date(currentDay.sunset) : undefined}
                            weeklyHourlyForecast={weeklyHourlyForecast}
                            showWeeklyForecast={showWeeklyForecast}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default Area;
