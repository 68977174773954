/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Area, AreaIdName } from '../../types/Area';
import { DailyForecast, HourlyForecast } from '../../types/Forecast';
import { useRecoilState } from 'recoil';
import { compareAreasState } from '../../state/compareAreasState';
import { maxAreas } from '../compare/Compare';
import AreaSearchCardComponent from '../cards/AreaSearchCard';
import DailyForecastCardComponent from '../cards/DailyForecastCard';
import DailyForecastCardMobileComponent from '../cards/DailyForecastCardMobile';
import { useNavigate } from 'react-router-dom';
import { compareAreasIdNameState, removeAreaIdName } from '../../state/areasState';

type DailyForecastsCompareProps = {
    areas: Area[];
    dailyForecasts: DailyForecast[];
    bestTimesToClimb: HourlyForecast[];
    allHourlyForecasts: HourlyForecast[];
    onClick?: (id: string) => void;
    currentArea?: Area | undefined;
    setSelected?: (area: string) => void;
};

const DailyForecastsCompare = ({
    areas,
    dailyForecasts,
    bestTimesToClimb,
    allHourlyForecasts,
    currentArea,
    setSelected = () => {
        return;
    },
    onClick = () => {
        return;
    },
}: DailyForecastsCompareProps) => {
    const [cards, setCards] = useRecoilState(compareAreasState);
    const [compareAreasIdName, setCompareAreasIdNames] = useRecoilState(
        compareAreasIdNameState
    );
    const navigate = useNavigate();
    const handleRemoveCrag = (event: any, areaIdName: AreaIdName) => {
        removeAreaIdName(areaIdName, compareAreasIdName, setCompareAreasIdNames);
        const query = new URLSearchParams(location.search);
        const areasParam = query.get('areas');
        const currentAreas = areasParam ? areasParam.split(',') : [];
    
        // Remove the specific ID
        const updatedAreas = currentAreas.filter(id => id !== areaIdName.id);
    
        let queryString = undefined;
        if (updatedAreas.length > 0) {
            queryString = `areas=${updatedAreas.join(',')}`;
        }
        // Construct the new URL with updated query parameters
        const newUrl = queryString ? `${location.pathname}${queryString ? `?${queryString}` : ''}` : `${location.pathname}` ;

        // Navigate to the updated URL
        navigate(newUrl);
        setCards(cards.filter((card) => card.id !== areaIdName.id));
        setSelected('graph');
    };
    const cardProps = {
        displayAreaLink: true,
        displayActionsButton: true,
        onRemoveCrag: handleRemoveCrag,
    };

    const emptyAreas = maxAreas - areas.length;
    return (
        <div className="flex items-center justify-center flex-row">
            <div className="md:grid md:grid-cols-2 justify-items-center items-center lg:min-w-[1000px] lg:grid-cols-4 w-full md:w-fit gap-4 lg:gap-8 hide-scroll-bar mb-4">
                {dailyForecasts &&
                    dailyForecasts.map((forecast: DailyForecast) => (
                        <div key={`daily-forecast-container-${forecast.areaId}`}>
                            <div
                                className="md:flex hidden"
                                key={`daily-forecast-card-${forecast.areaId}`}
                            >
                                <DailyForecastCardComponent
                                    bestTimeToClimb={bestTimesToClimb?.find(
                                        (hourlyForecast: HourlyForecast) =>
                                            hourlyForecast.areaId === forecast.areaId
                                    )}
                                    key={forecast.areaId}
                                    id={forecast.areaId}
                                    hourlyForecasts={allHourlyForecasts?.filter(
                                        (hourlyForecast: HourlyForecast) => hourlyForecast.areaId === forecast.areaId
                                    )}                                    
                                    forecast={forecast}
                                    className={`${currentArea !== undefined &&
                                            currentArea.id === forecast.areaId
                                        ? 'border-2 border-primary'
                                        : ''
                                    } cursor-pointer snap-normal snap-center`}
                                    displayAreaName={true}
                                    displayDate={false}
                                    areaName={forecast.areaName}
                                    onClick={() => onClick(forecast.areaId)}
                                    {...cardProps}
                                />
                            </div>
                            <div
                                className="md:hidden w-full"
                                key={`daily-forecast-card-mobile-${forecast.areaId}`}
                            >   
                                <DailyForecastCardMobileComponent
                                    bestTimeToClimb={bestTimesToClimb?.find(
                                        (hourlyForecast: HourlyForecast) =>
                                            hourlyForecast.areaId === forecast.areaId
                                    )}
                                    hourlyForecasts={allHourlyForecasts?.filter(
                                        (hourlyForecast: HourlyForecast) => hourlyForecast.areaId === forecast.areaId
                                    )}
                                    key={forecast.areaId}
                                    id={forecast.areaId}
                                    forecast={forecast}
                                    className={`${currentArea !== undefined &&
                                            currentArea.id === forecast.areaId
                                        ? 'border-2 border-primary'
                                        : ''
                                    } cursor-pointer snap-normal snap-center`}
                                    displayAreaName={true}
                                    areaName={forecast.areaName}
                                    onClick={() => onClick(forecast.areaId)}
                                    {...cardProps}
                                    displayDate={false}
                                />
                            </div>
                        </div>
                    ))}
                {emptyAreas > 0 &&
                    Array.from({ length: emptyAreas }, (_, index) => (
                        <div
                            className={`${emptyAreas > 0 && index === 0 ? 'flex' : 'hidden md:flex'
                            }`}
                            key={`area-search-${index}`}
                        >
                            <AreaSearchCardComponent
                                id={index}
                                key={`area-search-card-${index}`}
                                className='card-layout lg:min-h-[14rem] lg:min-w-[14rem]'
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DailyForecastsCompare;
