import {
    useState, useEffect, Fragment
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { HiCheck } from 'react-icons/hi2';
import { AREAS_CITIES_BY_NAME } from '../../graphql/areas/queries';
import { Combobox, Transition } from '@headlessui/react';
import { areasIdNameState, favoriteAreasIdNameState, loadCurrentLocation } from '../../state/areasState';
import { Area, AreaIdName } from '../../types/Area';
import { FaHeart, FaLocationDot } from 'react-icons/fa6';
import { cleanString } from '../../utils/CommonUtils';
import { citiesState } from '../../state/citiesState';
import { City } from '../../types/City';

type AreasComboboxProps = {
    onChange: (selectedAreaIdName: (AreaIdName | City)) => void;
    placeholderText: string;
    className?: string;
    blur?: () => void;
    showFavorites?: boolean;
    showCurrentLocation?: boolean;
    autoFocus?: boolean;
    onFocus?: () => void;
    includeCities?: boolean;
}

export default function AreasComboboxComponent({onChange, placeholderText, blur, className: position = 'relative', showFavorites=true,  showCurrentLocation = true, autoFocus=false, onFocus, includeCities=false}: AreasComboboxProps){
    const [areas, setAreas] = useRecoilState(areasIdNameState);
    const [cities, setCities] = useRecoilState(citiesState);

    const [refetch, setRefetch] = useState(false);
    const [query, setQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState<string|null>(null);
    const [getAreasCities, { loading, data }] = useLazyQuery(AREAS_CITIES_BY_NAME);
    const [favoriteAreasIdName] = useRecoilState(favoriteAreasIdNameState);

    const currentLocationAreaIdName: AreaIdName ={
        id: 'cl',
        name: 'Current Location',
        path: ''
    };

    const updateCurrentLocation = useRecoilCallback(
        ({ set }) => async () => {
            await loadCurrentLocation(set);
        },
        []
    );

    useEffect(() => {
        if (data && data.areasCitiesByName && data.areasCitiesByName.areas && data.areasCitiesByName.areas.length > 0) {
            setAreas(data.areasCitiesByName.areas);
        }
        if (data && data.areasCitiesByName && data.areasCitiesByName.cities && data.areasCitiesByName.cities.length > 0) {
            setCities(data.areasCitiesByName.cities);
        }
    }, [data]);
   
    const handleChange = (selectedAreaCity: any) => {
        if (selectedAreaCity && 'id' in selectedAreaCity) {
            if (selectedAreaCity.id === 'cl') {
                updateCurrentLocation();
            }
            onChange(selectedAreaCity);
            setSelectedItem(selectedAreaCity.id);
        } else if (selectedAreaCity && 'countryCode' in selectedAreaCity && 'stateCode' in selectedAreaCity && 'name' in selectedAreaCity) {
            onChange(selectedAreaCity);
            setSelectedItem(`${selectedAreaCity.countryCode}/${selectedAreaCity.stateCode}/${selectedAreaCity.name}`);
        }
    };

    const updateAreas = (updatedQuery: string) => {
        if ((updatedQuery.length === 3 ||
            (updatedQuery.length >= 3 && (areas.length > 49 || cities.length > 49)) ||
            (updatedQuery.length >= 3 && (areas.length === 0 && cities.length === 0)) || 
            (updatedQuery.length >= 3 && updatedQuery.substring(0,3) !== query.substring(0,3) && refetch === false)) && 
            updatedQuery !== query) {
            setRefetch(true);
            getAreasCities({ variables: { name: updatedQuery, includeCities } });
        }
        setRefetch(false);
        setQuery(updatedQuery);
    };

    const filteredAreas =
        query === '' || query.length < 3
            ? []
            : areas.filter((area) => {
                return cleanString(area.name.toLowerCase()).includes(cleanString(query.toLowerCase()));
            }).map((area) => {
                const areaPath = area.path.split(',');
                areaPath.pop();
                areaPath.shift();
                return {
                    name: area.name,
                    path: areaPath,
                    id: area.id
                };
            });

    const filteredCities =
        query === '' || query.length < 3
            ? []
            : cities.filter((city) => {
                return cleanString(city.name.toLowerCase()).includes(cleanString(query.toLowerCase()));
            }).map((city) => {
                const cityPath = city.path.split(',');
                cityPath.pop();
                cityPath.shift();
                return {
                    name: city.name,
                    state: city.state,
                    path: cityPath,
                    countryCode: city.countryCode,
                    stateCode: city.stateCode,
                    latitude: city.latitude,
                    longitude: city.longitude
                };
            });

    const favoriteAreas: AreaIdName[] = favoriteAreasIdName.map((areaIdName: AreaIdName) => {
        return {
            name: areaIdName.name,
            path: areaIdName.path.replaceAll(',', '->'),
            id: areaIdName.id
        };
    });

    return (
        <div onBlur={blur} className={`search-bar-size ${position}`}>
            <Combobox value={selectedItem} onChange={(selectedAreaCity) => handleChange(selectedAreaCity)} immediate={true} nullable >
                <div className="mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white-secondary text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-dark focus:ring-0 bg-white-secondary"
                            displayValue={(area: Area) => area ? area.name : ''}
                            onChange={(event) => updateAreas(event.target.value)}
                            placeholder={placeholderText}
                            autoFocus={autoFocus}
                            autoComplete="off"
                            onFocus={onFocus}
                        />
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >   
                        <Combobox.Options className="absolute mt-1 max-h-40 md:max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            {(showFavorites || showCurrentLocation) && !loading && query.length === 0 && (
                                <div>
                                    {showCurrentLocation && (
                                        <>  
                                            <Combobox.Option
                                                key='current-location-option'
                                                className={({ active }) =>
                                                    `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                                                        active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                                    }`
                                                }
                                                value={currentLocationAreaIdName}
                                            >
                                                <>
                                                    <div className='block truncate'>
                                                        <span className="font-bold flex flex-row items-center"> 
                                                            <FaLocationDot className='fill-red mr-1'/> 
                                                            <div className='whitespace-nowrap overflow-ellipsis overflow-hidden'>Get Current Location</div>
                                                        </span>
                                                    </div>
                                                </>
                                            </Combobox.Option>
                                        </>
                                    )
                                    } 
                                    {showFavorites && favoriteAreas.length > 0 && (
                                        <>  
                                            <p className='font-bold border-b-2 border-gray-light text-center'>Favorites</p>
                                            {favoriteAreas.map((area) => (
                                                <Combobox.Option
                                                    key={`${area.name}-${area.id}`}
                                                    className={({ active }) =>
                                                        `relative cursor-pointer select-none py-2 pl-2 pr-4 ${
                                                            active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                                        }`
                                                    }
                                                    value={area}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <div
                                                                className={`block truncate ${
                                                                    selected ? 'font-medium' : 'font-normal'
                                                                }`}
                                                            >
                                                                <span className="font-bold flex flex-row items-center"> 
                                                                    <FaHeart className='fill-red mr-1'/> 
                                                                    <div className='whitespace-nowrap overflow-ellipsis overflow-hidden'>{area.name}</div>
                                                                </span>
                                                                {`${area.path}`}
                                                            </div>
                                                            {selected ? (
                                                                <span
                                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                        active ? 'text-white' : 'text-gray-dark'
                                                                    }`}
                                                                >
                                                                    <HiCheck className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                    
                                        </>
                                    )
                                    }  
                                </div>

                            )}
                            
                            {!loading && (filteredAreas.length === 0 && filteredCities.length === 0) && query !== '' && query.length >= 3 && (
                                <div className="relative cursor-default select-none px-4 text-gray">
                              Nothing found.
                                </div>
                            )}

                            {loading && (filteredAreas.length === 0 && filteredCities.length === 0) && query !== '' && query.length >= 3 && (
                                <div className="relative cursor-default select-none px-4 text-gray">
                              Searching...
                                </div>
                            )}

                            {(filteredAreas.length === 0 && filteredCities.length === 0) && query !== '' && query.length < 3 && (
                                <div className="relative cursor-default select-none px-4 text-gray">
                             Keep typing, need at least 3 characters to search.
                                </div>
                            )}

                            {filteredAreas.length > 49 && (
                                <div className="relative cursor-default select-none px-4 font-bold text-primary-dark text-center">
                                Showing first 50 results!
                                </div>
                            )}

                            {filteredAreas.length > 0 &&
                             (
                                 <>
                                     <p className='font-bold border-b-2 border-gray-light text-center'>Climbing Areas</p>
                                     {filteredAreas.map((area) => (
                                         <Combobox.Option
                                             key={`${area.name}-${area.id}`}
                                             className={({ active }) =>
                                                 `relative max-h-40 overflow-auto cursor-pointer select-none py-2 pl-2 pr-4 ${
                                                     active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                                 }`
                                             }
                                             value={area}
                                         >
                                             {({ selected, active }) => (
                                                 <>
                                                     <p
                                                         className={`block truncate ${
                                                             selected ? 'font-medium' : 'font-normal'
                                                         }`}
                                                     >
                                                         <span className="font-bold"> {`${area.name}`}</span>
                                                         <br/>
                                                         {`${area.path}`}
                                                     </p>
                                                     {selected ? (
                                                         <span
                                                             className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                 active ? 'text-white' : 'text-gray-dark'
                                                             }`}
                                                         >
                                                             <HiCheck className="h-5 w-5" aria-hidden="true" />
                                                         </span>
                                                     ) : null}
                                                 </>
                                             )}
                                         </Combobox.Option>
                                     ))}
                                 </>
                             )
                                
                            }
                            {filteredCities.length > 0 && (
                                <>
                                    <p className='font-bold border-b-2 border-gray-light text-center'>Cites</p>

                                    {filteredCities.map((city) => (
                                        <Combobox.Option
                                            key={`${city.state}-${city.name}`}
                                            className={({ active }) =>
                                                `relative max-h-40 overflow-auto cursor-pointer select-none py-2 pl-2 pr-4 ${
                                                    active ? 'bg-primary-dark text-white' : 'text-gray-dark'
                                                }`
                                            }
                                            value={city}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <p
                                                        className={`block truncate ${
                                                            selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    >
                                                        <span className="font-bold"> {`${city.name}`}</span>
                                                        <br/>
                                                        {`${city.path}`}
                                                    </p>
                                                    {selected ? (
                                                        <span
                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                active ? 'text-white' : 'text-gray-dark'
                                                            }`}
                                                        >
                                                            <HiCheck className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))}
                                </>
                            )
                            }   
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}